import React from 'react';

import Layout from '../../layout/Layout.js';

import enMessages from '../../i18n/dengiru/privacy-policy/en.js';
import ruMessages from '../../i18n/dengiru/privacy-policy/ru.js';

import {
  PrivacyPolicyPageContainer,
  PrivacyPolicyPageContent,
} from '../../components/privacyPolicy';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const PrivacyPolicy = ({ pathContext: { locale } }) => (
  <Layout locale={locale}>
    <PrivacyPolicyPageContainer>
      <PrivacyPolicyPageContent content={messages[locale].content}/>
    </PrivacyPolicyPageContainer>
  </Layout>
);

export default PrivacyPolicy;

