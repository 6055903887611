
import { styles } from '../../../components/privacyPolicy/PrivacyPolicyPageContent';

const privacyPolicyLink = "/legal/rules-dengu-ru/politika-obrabotki-personalnyh-dannyh-v-ooo-bank-131.pdf";

export default {
  seo: {
    title: 'Privacy Policy | Bank 131',
    description: 'Privacy Policy'
  },
  title: 'Privacy Policy',
  content: `<div class="${styles.root}">
  <div class="${styles.pageWithoutBottomMargin}">
    <div class="${styles.documentDisclaimer}">
      <p>
        <strong>Disclaimer: </strong>This translation is for informational purposes only and is not a legal document.
        <br />
        For the official Privacy Policy in Russian, please refer to <a target="_blank" href=${privacyPolicyLink}>the legal document</a> in its original language.
      </p>
    </div>
  </div>

  <div class="${styles.page}">
    <div class="${styles.documentHeader}">
      <p>
        <strong>APPROVED</strong>
      </p>
      <p>By order of the Chairman</p>
      <p>of the Management Board</p>
      <p>of Bank 131 LLC</p>
      <p>September 19, 2023</p>
      <p>No. 159</p>
    </div>

    <div class="${styles.documentNameBox}" >
      <h1 class="${styles.documentName}" >
        POLICY<br />
        regarding the processing of personal data at Bank 131 LLC
      </h1>
    </div>
  </div>

  <div class="${styles.page}">
    <div class="${styles.contents}">
      <h2 class="${styles.heading}">Content</h2>
      <ul class="${styles.list}">
        <li>
          <a href="#heading1">Terms and definitions</a>
        </li>
        <li>
          <a href="#heading2">1. General provisions</a>
        </li>
        <li>
          <a href="#heading3">
            2. Legal basis for processing personal data
          </a>
        </li>
        <li>
          <a href="#heading4">
            3. The purpose of the collection and processing of personal data. The volume and categories of personal data processed. Categories of personal data subjects.
          </a>
        </li>
        <li>
          <a href="#heading5">
            4. The procedure and conditions for processing personal data
          </a>
        </li>
        <li>
          <a href="#heading6">
            5. Organization of personal data protection
          </a>
        </li>
        <li>
          <a href="#heading7">6. Use of cookies</a>
        </li>
        <li>
          <a href="#heading8">
            7. Updating, correction, deletion, destruction of personal data, responses to requests from subjects for access to personal data
          </a>
        </li>
        <li>
          <a href="#heading9">8. Final provisions</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="${styles.page}">
    <h2 class="${styles.heading}" id="heading1">
      Terms and definitions
    </h2>
    <p>
      <strong>Personal Data Information System (PDIS) Administrator is</strong> an employee of the Bank who ensures the proper functioning of the PDIS, who is responsible for ensuring the stable operability of the PDIS elements when processing personal data, and also provides and delimits the access of PDIS users to the elements storing personal data.
    </p>
    <p>
      <strong>Automated processing of personal data is</strong> the processing of personal data using computer technology.
    </p>
    <p>
      <strong>Bank is</strong> Bank 131 LLC, an operator of personal data independently or jointly with other persons organizing and (or) processing personal data, as well as determining the purposes of processing personal data, the composition of personal data to be processed, actions (operations) performed with personal data.
    </p>
    <p>
      <strong>Blocking of personal data is</strong> the temporary termination of the processing of personal data (except in cases where processing is necessary to clarify personal data).
    </p>
    <p>
      <strong>Personal Data Information System (PDIS) is</strong> a set of personal data contained in databases and information technologies and technical means that ensure their processing.
    </p>
    <p>
      <strong>Confidentiality of personal data is not</strong> disclosure to third parties and not distribution of personal data without the consent of the personal data subject, unless otherwise provided by Federal Law No. 152-FZ, by operators and other persons who have access to personal data.
    </p>
    <p>
      <strong>Depersonalization of personal data is</strong> an action that makes it impossible to determine the identity of personal data to a specific personal data subject without using additional information.
    </p>
    <p>
      <strong>Personal data processing is</strong> any action (operation) or set of actions (operations) performed with or without the use of automation tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, modification), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data.
    </p>
    <p>
      <strong>Responsible for the organization of personal data processing is</strong> an employee of the Bank appointed by the order of the Chairman of the Management Board responsible for the organization of personal data processing.
    </p>
    <p>
      <strong>Personal data is</strong> any information related directly or indirectly to identified or identifiable individual (subject of personal data).
    </p>
    <p>
      <strong>Personal data authorized by the subject of personal data for distribution is</strong> personal data, to which access to an unlimited number of persons is provided by the subject of personal data by giving consent to the processing of personal data authorized by the subject of personal data for distribution in the manner prescribed by Federal Law No. 152-FZ.
    </p>
    <p>
      <strong>Provision of personal data is</strong> actions aimed at disclosing personal data to a certain person or a certain scope of persons.
    </p>
    <p>
      <strong>Distribution of personal data is</strong> actions aimed at disclosing personal data to an indefinite scope of persons.
    </p>
    <p>
      <strong>Cross–border transfer of personal data is</strong> the transfer of personal data to the territory of a foreign state to an authority of a foreign state, a foreign individual or a foreign legal entity.
    </p>
    <p>
      <strong>Destruction of personal data is</strong> actions as a result of which it becomes impossible to restore the content of personal data in the personal data information system and (or) as a result of which the tangible media of personal data are destroyed.
    </p>
    <p>
      <strong>Cookies</strong> are temporary files stored in the browser that contain various information from a particular site.
    </p>
  </div>

  <div class="${styles.page}">
    <h2 class="${styles.heading}" id="heading2">
      1. General provisions
    </h2>
    <p>1.1. This Policy regarding the processing of personal data at Bank 131 LLC (hereinafter referred to as the Policy) has been developed in compliance with the requirements of paragraph 2, Part 1, Article 18.1 of Federal Law No. 152–FZ dated 07/27/2006 "On Personal Data" in order to ensure the protection of human and civil rights and freedoms when processing his personal data, including protection of the rights to privacy, personal and family secrets.</p>
    <p>1.2. The Policy defines the basic rights and obligations of the Bank and personal data subjects, the purposes of personal data processing, the legal grounds for processing personal data, categories of processed personal data, categories of personal data subjects, the procedure and conditions for processing personal data, as well as measures to ensure the security of personal data during their processing applied by the Bank.</p>
    <p>1.3. This Policy applies to all personal data processed by the Bank.</p>
    <p>1.4. The provisions of this Policy are binding on all employees of the Bank who directly process personal data.</p>
    <p>1.5. This Policy is the fundamental internal document of the Bank regulating the general provisions on the processing of personal data and in compliance with the requirements of Part 2 of Article 18.1 of Federal Law No. 152-FZ, this Policy is published in free access on the Internet information and telecommunications network on the Bank's website at: <a href="https://131.ru/en/">www.131.ru</a>.</p>
  </div>

  <div class="${styles.page}">
    <h2 class="${styles.heading}" id="heading3">
      2. Legal basis for processing personal data
    </h2>
    <p>2.1. The legal basis for the processing of personal data, pursuant to which and in accordance with which the Bank processes personal data, is a set of regulatory legal acts, internal documents of the Bank, as well as other documents, including:</p>
    <p>- The Constitution of the Russian Federation</p>
    <p>- The Labor Code of the Russian Federation;</p>
    <p>- The Civil Code of the Russian Federation;</p>
    <p>- The Tax Code of the Russian Federation;</p>
    <p>- Federal Law No. 395-1 dated 02.12.1990 "On Banks and Banking Activities";</p>
    <p>- Federal Law No. 115-FZ dated 08/07/2001 "On Countering the Legalization (Laundering) of Proceeds from Crime and the Financing of Terrorism" (hereinafter – Federal Law No. 115-FZ);</p>
    <p>- Federal Law No. 149-FZ dated 07/27/2006 "On Information, Information Technologies and Information Protection" (hereinafter – Federal Law No. 149-FZ);</p>
    <p>- Federal Law No. 161-FZ dated 06/27/2011 "On the National Payment System";</p>
    <p>- Federal Law No. 27-FZ dated 04/01/1996 "On Individual (Personalized) Accounting in the system of compulsory Pension Insurance and compulsory Social Insurance";</p>
    <p>- Federal Law No. 39-FZ dated 04/22/1996 "On the Securities Market";</p>
    <p>- Federal Law No. 62-FZ dated 05/31/2002 "On Citizenship of the Russian Federation";</p>
    <p>- Federal Law No. 173-FZ dated 10.12.2003 "On Currency Regulation and Currency Control";</p>
    <p>- Federal Law No. 63-FZ dated 04/06/2011 "On Electronic Signature";</p>
    <p>- Federal Law No. 208-FZ dated 12/26/1995 "On Joint-Stock Companies";</p>
    <p>- Federal Law No. 14-FZ dated 08.02.1998 "On Limited Liability Companies";</p>
    <p>- Federal Law No. 125-FZ dated 10/22/2004 "On Archival Business in the Russian Federation";</p>
    <p>- The Law of the Russian Federation dated 07.02.1992 No. 2300-1 "On Consumer Rights Protection";</p>
    <p>- Federal Law No. 167-FZ dated 12/15/2001 "On Compulsory Pension Insurance in the Russian Federation";</p>
    <p>- Federal Law No. 402-FZ dated 06.12.2011 "On Accounting";</p>
    <p>- Order of the Ministry of Labor of the Russian Federation dated 05/19/2021 No. 320 "On approval of the form, procedure for maintaining and storing employment books";</p>
    <p>- Instruction of the Bank of Russia dated 08/09/2004 No. 1486-y "On qualification requirements for special Officials responsible for Compliance with Internal Control Rules in order to counteract the legalization (Laundering) of Proceeds from crime and the financing of terrorism and its implementation programs in credit institutions";
    <p>- The Charter of the Bank;</p>
    <p>- the consent of the subject to the processing of personal data (in cases not directly provided for by the legislation of the Russian Federation, but corresponding to the powers of the operator);</p>
    <p>- agreements concluded between the Bank and personal data subjects;</p>
    <p>- agreements concluded between the Bank and third parties-counterparties;</p>
    <p>- Basic Banking License No. 3538 (issued by the Bank of Russia on 04/12/2019);</p>
    <p>- Other documents.</p>
  </div>

  <div class="${styles.page}" >
    <h2 class="${styles.heading}" id="heading4">
      3. The purpose of the collection and processing of personal data.  The volume and categories of personal data processed. Categories of personal data subjects.
    </h2>
    <p>3.1. The processing of personal data by the Bank is limited to achieving specific, predetermined and legitimate goals. Processing of personal data incompatible with the purposes of personal data collection is not allowed.</p>
    <p>3.2. The content and volume of personal data processed in the Bank must correspond to the stated purposes of processing. The processed personal data should not be redundant in relation to the stated purposes of their processing.</p>
    <p>3.3. The categories of personal data subjects are defined by the Bank's internal documents, including:</p>
    <p>- Bank employees, former employees, candidates for vacant positions, as well as relatives of employees;</p>
    <p>- clients and counterparties of the Bank (individuals);</p>
    <p>- representatives/employees of the Bank's clients and counterparties (legal entities). Depending on the purposes of personal data processing, the Bank's internal documents define for each category of personal data subjects a specific list of personal data processed by the Bank in relation to specific purposes. The list of purposes for processing personal data of personal data subjects and categories of personal data subjects whose personal data are processed by the Bank is subject to posting on the Bank's official website on the Internet information and telecommunications network at: <a href="https://131.ru/en/">www.131.ru</a>.</p>
    <p>3.4. The Bank does not process special categories of personal data related to race, political views, nationality, religious or philosophical beliefs, or intimate life. 3.</p>
    <p>3.5. The Bank processes special categories of personal data related to the health status and criminal record of individuals in cases provided for by the legislation of the Russian Federation and internal documents of the Bank and if there are legal reasons provided for by Federal Law No. 152-FZ.</p>
    <p>3.6. The Bank does not process biometric personal data.</p>
  </div>

  <div class="${styles.page}">
    <h2 class="${styles.heading}" id="heading5">
      4. The procedure and conditions for processing personal data
    </h2>
    <p>4.1. The Bank collects, records, systematizes, accumulates, stores, clarifies (updates, changes), extracts, uses, transfers (distributes, provides, accesses), depersonalizes, blocks, deletes, and destroys personal data of personal data subjects.</p>
    <p>4.2. The processing of personal data is carried out with the consent of the personal data subject to the processing of his personal data, as well as without it in the cases provided for in Article 6 of Federal Law No. 152-FZ.  The requirements for the content of consent to the processing of personal data authorized by the personal data subject for distribution are established by the authorized body for the protection of the rights of personal data subjects.</p>
    <p>4.3. The processing of personal data provided by personal data subjects is carried out by the Bank for each purpose of their processing in the following ways:</p>
    <p>- non-automated processing of personal data;</p>
    <p>- automated processing of personal data with or without transmission of the received information via information and telecommunication networks;</p>
    <p>- mixed processing of personal data.</p>
    <p>4.4. The processing of personal data authorized by the subject of personal data for distribution is carried out in compliance with the prohibitions and conditions provided for in Article 10.1 of Federal Law No. 152-FZ.</p>
    <p>4.5. The term and condition for the termination of personal data processing by the Bank is the achievement of the goals of personal data processing or the loss of the need to achieve them, the expiration of the contract / consent or revocation of the consent of the personal data subject to the processing of his personal data, as well as the identification of unlawful processing of personal data, termination of the Bank's activities as a legal entity (liquidation, reorganization).</p>
    <p>4.6. If the personal data subject withdraws consent to the processing of his personal data, the Bank is obliged to stop processing them (ensure the termination of such processing if the processing of personal data is carried out by another person on behalf of the Bank) and if the storage of personal data is no longer required for the purposes of processing personal data, destroy personal data (ensure their destruction if the processing of personal data is carried out by another person on behalf of the Bank) within a period not exceeding 30 (thirty) days from the date of receipt of the specified recall, unless otherwise provided for in the agreement to which the personal data subject is a party, beneficiary or guarantor, another agreement between the Bank and the personal data subject, or if the Bank is not entitled to process personal data without the consent of the personal data subject on the basis provided for by Federal Law No. 152-FZ or other federal laws.</p>
    <p>4.7. Personal data is stored by the Bank in a form that allows determining the subject of personal data, no longer than the purposes of personal data processing require, unless the period of personal data storage is established by federal law, an agreement to which the personal data subject is a party, beneficiary or guarantor. The processed personal data is subject to destruction or depersonalization upon achievement of the processing goals or in case of loss of the need to achieve these goals, unless otherwise provided by federal law.</p>
    <p>4.8. The terms of storage of personal data are determined by the Bank, in general, in accordance with the terms of the requirements of the legislation of the Russian Federation (labor, pension, tax, accounting, etc.), established by Order of the Federal Archive of 20.12.2019 No. 236 "On approval" of the List of standard administrative archival documents formed in the course of the activities of state bodies, local governments and organizations, indicating the terms of their storage", the limitation period of mutual claims of the Bank and the subject of personal data.</p>
    <p>4.9. The Bank has the right to entrust the processing of personal data to another person with the consent of the personal data subject, unless otherwise provided by Federal Law, on the basis of an agreement concluded with this person. A person processing personal data on behalf of the Bank is obliged to comply with the principles and rules of personal data processing provided for by Federal Law No. 152-FZ, to respect the confidentiality of personal data, and to take necessary measures aimed at ensuring the fulfillment of obligations provided for by Federal Law No. 152-FZ. The Bank's order defines a list of personal data, a list of actions (operations) with personal data that will be performed by the person processing personal data, the purpose of their processing, establishes the obligation of such a person to respect the confidentiality of personal data, the requirements provided for in part 5 of Article 18 and Article 18.1 of Federal Law No. 152-FZ, obligation, upon the Bank's request, during the term of the order, including prior to the processing of personal data, to provide documents and other information confirming the adoption of measures and compliance with the requirements established in accordance with Federal Law No. 152-FZ in order to fulfill the Bank's order, the obligation to ensure security of personal data during their processing, and also the requirements are specified for the protection of processed personal data in accordance with Article 19 of Federal Law No. 152-FZ, including the requirement to notify the Bank of the cases provided for in Part 3.1 of Article 21 of Federal Law No. 152-FZ. If the Bank entrusts the processing of personal data to a foreign individual or a foreign legal entity, the Bank and the person processing personal data on behalf of the operator are responsible to the personal data subject for the actions of these persons.</p>
    <p>4.10. The Bank prohibits making decisions based solely on automated processing of personal data that give rise to legal consequences with respect to the subject of personal data or otherwise affect his rights and legitimate interests, except in cases and conditions provided for by the legislation of the Russian Federation.</p>
    <p>4.11. The Bank transfers personal data to the bodies of inquiry and investigation and other authorized bodies on the grounds provided for by the current legislation of the Russian Federation.</p>
    <p>4.12. When collecting personal data, including through the Internet information and telecommunications network, the Bank ensures the recording, systematization, accumulation, storage, clarification (updating, modification), extraction of personal data of citizens of the Russian Federation using databases located on the territory of the Russian Federation, except in cases provided for by the legislation of the Russian Federation.</p>
    <p>4.13. In the course of its activities, the Bank is entitled to carry out cross-border transfer of personal data in accordance with the procedure and in accordance with the requirements of Federal Law No. 152-FZ and international treaties of the Russian Federation.</p>
  </div>

  <div class="${styles.page}">
    <h2 class="${styles.heading}" id="heading6">
      5. Organization of personal data protection
    </h2>
    <p>5.1. Only those employees of the Bank whose job responsibilities determine the processing of personal data are allowed to process personal data. These employees have the right to process only those personal data that they need to perform their job duties.</p>
    <p>5.2. The Bank ensures the confidentiality of the personal data of the personal data subject, and also ensures the use of personal data exclusively for purposes consistent with Federal Law No. 152-FZ, a contract or other agreement concluded with the personal data subject.</p>
    <p>5.3. When processing personal data, the Bank takes the necessary legal, organizational and technical measures and ensures that they are taken to protect personal data from unlawful or accidental access to them, destruction, modification, blocking, copying, provision, distribution of personal data, as well as from other unlawful actions with respect to personal data.</p>
    <p>5.4. The processing of personal data by the Bank, carried out without the use of automation tools, is carried out in compliance with the procedure provided for by Decree of the Government of the Russian Federation dated September 15, 2008 No. 687 "On Approval of the Regulation on the specifics of personal data processing carried out without the use of automation tools", thus:</p>
    <p>- in relation to each category of personal data subjects, the places of storage of personal data (tangible media) have been determined and a list of persons processing personal data or having access to them has been established;</p>
    <p>- separate storage of personal data (tangible media), the processing of which is carried out for various purposes, is ensured, and when storing tangible media, conditions are observed that ensure the safety of personal data and exclude unauthorized access to them;</p>
    <p>- the list of measures necessary to ensure such conditions, the procedure for their adoption, as well as the list of persons responsible for the implementation of these measures, are established by the Bank.</p>
    <p>To ensure the security of personal data of personal data subjects during automated processing, the following measures are taken:</p>
    <p>- the accounting of machine-based personal data carriers is carried out;</p>
    <p>- the rules for access to personal data processed in the personal data information system (hereinafter - PDIS) are established, and registration and accounting of all actions performed with personal data in PDIS is also provided;</p>
    <p>- accounting of PDIS and data on personal data processed in them is carried out;</p>
    <p>- PDIS classification is carried out in accordance with the current legislative documents of the Russian Federation in the field of personal data, is formalized by a separate Act of PDIS classification and approved by the Chairman of the Board of the Bank;</p>
    <p>- personal computers that have access to personal data storage databases are protected by access passwords. Passwords for the first entry into the information system are set by the PDIS Administrator and are communicated individually to the employee who is allowed to work with personal data and processes personal data on this personal computer. At the first login to the information system, the employee changes the password set by the PDIS Administrator;</p>
    <p>- other measures provided for in the Regulations on the organization and conduct of work to ensure the security of personal data during their processing in personal data information systems;</p>
    <p>- The processing of personal data is carried out in compliance with the requirements provided for by Decree of the Government of the Russian Federation No. 1119 dated November 01, 2012 "On Approval of requirements for the protection of personal data during their processing in personal Data information systems".</p>
    <p>The Bank has taken the following measures necessary and sufficient to ensure the protection of personal data provided for by the legislation of the Russian Federation on personal data:</p>
    <p>- the person responsible for the organization of personal data processing has been appointed;</p>
    <p>- the person responsible for ensuring the security of personal data in the information system has been appointed;</p>
    <p>- Local acts on the processing of personal data have been issued, as well as local acts establishing procedures aimed at preventing and detecting violations of the legislation of the Russian Federation and eliminating the consequences of such violations.</p>
    <p>- legal, organizational and technical measures are applied to ensure the security of personal data in accordance with section 5 of this Policy;</p>
    <p>- internal control of compliance of personal data processing with the requirements of regulatory acts is carried out in order to identify violations of established procedures for processing personal data and eliminate the consequences of such violations;</p>
    <p>- the assessment of the harm that may be caused to personal data subjects in case of violation of Federal Law No. 152-FZ, the ratio of this harm and the measures taken by the Bank aimed at ensuring the fulfillment of obligations provided for by Federal Law No. 152-FZ was carried out;</p>
    <p>- The Bank's employees who directly process personal data are familiar with the provisions of the legislation of the Russian Federation on personal data, including the requirements for personal data protection, this Policy, and the Bank's local acts on personal data processing.</p>
    <p>5.5. The choice of information protection tools for the personal data protection system is carried out by the Bank in accordance with regulatory legal acts adopted by the Federal Security Service of the Russian Federation and the Federal Service for Technical and Export Control pursuant to Part 4 of Article 19 of Federal Law No. 152-FZ.</p>
  </div>

  <div class="${styles.page}">
    <h2 class="${styles.heading}" id="heading7">
      6. Use of cookies
    </h2>
    <p>6.1. The Bank uses cookies on the websites *.131.ru, *.dengi.ru . and other websites owned by the Bank (hereinafter collectively and individually referred to as the "Bank's website").</p>
    <p>6.2. The Bank receives personal data about users of the Bank's website, mobile applications of the Bank through cookies that are available and collected independently or with the involvement of the Yandex.Metrica service: specifically: the user's pseudonym, the user's address or the address of the user's device and its technical characteristics, through which the user accessed the Bank's website and (or) established a connection to the Bank's Internet service, the operating system used on the user's device, the version of the operating system, as well as information about the user, including IP address, user search queries, information about the browser and language used, dates and times of access to the site, internet addresses of web pages visited by the user, topics of information posted on the Bank's internet resources visited by the user, user ID converted by the Bank using a hash function or other modifications, device ID, geographical location, number of pages viewed, duration of stay on the Bank's website, requests that the user used when going to the website, pages from which the transitions were made, information about the mobile device, incl. device identifier, location information, system settings data, user CUS, User-Agent, source of advertising traffic, session identifier, authorization/registration time, token, time of each token check in relation to systems, identifiers of systems visited by the user, version mobile application, service user login, date/time of use of the service and any information that does not allow to uniquely identify the user or a specific individual, to provide the user with advertising information and analytical user information.</p>
    <p>6.3. The choice of the composition of cookies for processing depends on the browser and device used. The Bank's cookies can be used for:</p>
    <p>- authorization on the Bank's website as a registered user, provision of Bank services;</p>
    <p>- provision information about the Bank, its products and services;</p>
    <p>- improvement of products and (or) services, development of new products and (or) services of the Bank;</p>
    <p>- maintaining statistics about users;</p>
    <p>- storing personal preferences and user settings;</p>
    <p>- tracking the status of the user access session;</p>
    <p>- ensuring the functioning and improving the quality of the Bank's website;</p>
    <p>- using online forms on the Bank's website;</p>
    <p>- providing remote service;</p>
    <p>- formation of a list of interests, demonstration of Internet content to the user;</p>
    <p>- conducting analytics.</p>
    <p>6.4 Cookies can be managed independently. The browser and/or device used may be capable of blocking, deleting, or otherwise restricting the use of cookies.</p>
    <p>6.5 When deleting or restricting the use of cookies, some functions of the Bank's website or Bank's services may not be available.</p>
  </div>

  <div class="${styles.page}" >
    <h2 class="${styles.heading}" id="heading8">
      7. Updating, correction, deletion, destruction of personal data, responses to requests from subjects for access to personal data
    </h2>
    <p>7.1. Confirmation of the fact of personal data processing by the Bank, the legal basis and purposes of personal data processing, as well as other information specified in Part 7 of Article 14 of Federal Law No. 152-FZ, are provided by the Bank to the personal data subject or his representative within 10 (ten) business days from the moment of contacting or receiving a request from the personal data subject or his representative. This period may be extended, but not more than 5 (five) business days. For these purposes, the Bank sends a reasoned notice to the subject of personal data indicating the reasons for extending the period for providing the requested information.</p>
    <p>The information provided does not include personal data related to other personal data subjects, except in cases where there are legitimate reasons for the disclosure of such personal data.</p>
    <p>The recommended form of request of personal data subjects to receive information regarding the processing of personal data is in Appendix 1 to this Policy.</p>
    <p>The request can be sent in the form of an electronic document and signed with an electronic signature in accordance with Federal Law No. 63-FZ dated 04/06/2011 "On Electronic Signature".</p>
    <p>The bank provides the information specified in Part 7 of Art. 14 of Federal Law No. 152-FZ, to the subject of personal data or his representative in the form in which the relevant appeal or request was sent, unless otherwise indicated in the appeal or request.</p>
    <p>If the personal data subject's request (appeal) does not reflect all the necessary information in accordance with the requirements of Federal Law No. 152-FZ, or the subject does not have the right to access the requested information, then a reasoned refusal shall be sent to him/her within a period not exceeding 10 (ten) working days from the date of the request by the personal data subject or his/her representative, or from the date of receipt of the request by the personal data subject or his/her representative. The specified period may be extended, but not more than 5 (five) business days if the Bank sends a reasoned notification to the personal data subject indicating the reasons for extending the period for providing the requested information.</p>
    <p>The right of a personal data subject to access his personal data may be restricted in accordance with Part 8 of Article 14 of Federal Law No. 152-FZ, including if the access of a personal data subject to his personal data violates the rights and legitimate interests of third parties.</p>
    <p>7.2. In case of identification of inaccurate personal data when contacting a personal data subject or his representative, or at their request or at the request of the Federal Service for Supervision of Communications, Information Technology, and Mass Media, the Bank blocks personal data related to this personal data subject from the moment of such request or receipt of the specified request for the verification period, if the blocking of personal data does not violate the rights and legitimate interests of the personal data subject or third parties.</p>
    <p>If the fact of inaccuracy of personal data is confirmed, the Bank, on the basis of the information provided by the subject of personal data or his representative or the Federal Service for Supervision of Communications, Information Technology, and Mass Media, or other necessary documents, clarifies the personal data within 7 (seven) business days from the date of submission of such information and removes the blocking of personal data.</p>
    <p>7.3. If unlawful processing of personal data is detected upon an appeal (request) from the subject of personal data or his representative or the Federal Service for Supervision of Communications, Information Technology, and Mass Media, the Bank blocks unlawfully processed personal data relating to this subject of personal data from the moment of such appeal or receipt of the request.</p>
    <p>7.4. If the Bank, the Federal Service for Supervision of Communications, Information Technology, and Mass Media or any other interested person identifies the fact of unlawful or accidental transfer (provision, distribution) of personal data (access to personal data), which resulted in violation of the rights of personal data subjects, the Bank:</p>
    <p>- within 24 hours, notifies the Federal Service for Supervision of Communications, Information Technology, and Mass Media of the incident, the alleged causes that led to the violation of the rights of personal data subjects, the alleged harm caused to the rights of personal data subjects, and the measures taken to eliminate the consequences of the incident, as well as provides information about the person authorized by the Bank to interact with the Federal Service for Supervision of Communications, Information Technology, and Mass Media on issues related to the incident;</p>
    <p>- within 72 hours, notifies the Federal Service for Supervision of Communications, Information Technology, and Mass Media of the results of an internal investigation of the identified incident and provides information about the persons whose actions caused it (if any).</p>
    <p>In the event of a change in the information specified in Part 3 of Article 22 of Federal Law No. 152-FZ, the Bank, no later than the 15th day of the month following the month in which such changes occurred, notifies the authorized body for the protection of the rights of personal data subjects of all changes that occurred during the specified period. In case of termination of personal data processing, the Bank notifies the authorized body for the protection of the rights of personal data subjects within 10 (ten) business days from the date of termination of personal data processing.</p>
    <p>The Bank shall inform the authorized body for the protection of the rights of personal data subjects upon request of this body of the necessary information within 10 (ten) business days from the date of receipt of such request. The specified period may be extended, but not more than 5 (five) business days if the Bank sends a reasoned notification to the authorized body for the protection of the rights of personal data subjects indicating the reasons for extending the period for providing the requested information.</p>
    <p>7.5. If a personal data subject applies to the Bank with a request to terminate the processing of personal data, the Bank is obliged, within a period not exceeding 10 (ten) business days from the date of receipt by the Bank of the relevant request, to terminate their processing or ensure the termination of such processing (if such processing is carried out by the person processing personal data), except for the cases provided for in paragraphs 2-11 of part 1 of article 6, part 2 of article 10 and part 2 of article 11 of Federal Law No. 152-FZ. The specified period may be extended, but not more than 5 (five) business days if the Bank sends a reasoned notification to the personal data subject indicating the reasons for extending the period for providing the requested information.</p>
    <p>7.6. The procedure for the destruction of personal data by the Bank.</p>
    <p>7.6.1. Terms and conditions for the destruction of personal data by the Bank:</p>
    <p>- achieving the purpose of processing personal data or the loss of the need to achieve this goal – within 30 (thirty) days;</p>
    <p>- achieving the maximum retention period for documents containing personal data – within 30 (thirty) days;</p>
    <p>- the provision by the personal data subject (his representative) of confirmation that the personal data was obtained illegally or is not necessary for the stated purpose of processing – within 7 (seven) business days;</p>
    <p>- revocation by the subject of personal data of consent to the processing of his personal data, if their storage for the purpose of their processing is no longer required, within 30 (thirty) days.</p>
    <p>7.6.2. When the purpose of processing personal data is achieved, as well as in the event that the subject of personal data revokes consent to their processing, personal data is subject to destruction unless:</p>
    <p>- otherwise provided by the agreement to which the subject of personal data is a party, beneficiary or guarantor;</p>
    <p>- The Bank is not entitled to process personal data without the consent of the subject of personal data on the grounds provided for by Federal Law No. 152-FZ or other federal laws;</p>
    <p>- otherwise provided by another agreement between the Bank and the subject of personal data.</p>
    <p>7.6.3. The destruction of personal data is carried out by a commission established by order of the Chairman of the Board of the Bank.</p>
    <p>7.6.4. Confirmation of the destruction of personal data in the cases provided for in this section of the Policy is carried out in accordance with the requirements established by the authorized body for the protection of the rights of personal data subjects.</p>
    <p>7.6.5. The methods of destruction of personal data are established by the Bank's internal regulatory documents.</p>
  </div>

  <div class="${styles.page}">
    <h2 class="${styles.heading}" id="heading9">
      8. Final provisions
    </h2>
    <p>8.1. This Policy comes into force from the date of its approval. From the date of approval of this Policy, the Personal Data Processing Policy at Bank 131 LLC, approved by Order No. 51 of the Chairman of the Bank's Management Board dated 05/15/2021, becomes invalid.</p>
    <p>8.2. The Information Security Service of the Bank's Security Department is responsible for reviewing this Policy, for considering proposals from the Bank's employees to change and update it.</p>
    <p>8.3. This Policy is subject to revision in the event of changes in the legislation of the Russian Federation and regulatory legal acts on the processing and protection of personal data, as well as at the initiative of the Bank, but at least once every three years.</p>
    <p>8.4. This Policy is a publicly available document to be posted on the official website of the Bank: <a href="https://131.ru/en/">www.131.ru</a>.</p>
  </div>

  <div class="${styles.page}">
    <div class="${styles.right}">
      <p>
        <i>
          Appendix No.1<br />
          to the Personal Data Processing Policy<br />
          of Bank 131 LLC
        </i>
      </p>
    </div>

    <h2 class="${styles.textCenter}">
      The recommended form of request of personal data subjects to receive information regarding the processing of personal data
    </h2>

    <div class="${styles.applicationHeader}">
      <p>To the Chairman of the Management Board<br /> of Bank 131 LLC</p>
      <br />
      <div class="${styles.solid}"></div>
      <p class="${styles.textCenter}">(Full name of the applicant)</p>
      <p>address:</p>
      <br />
      <div class="${styles.solid}"></div>
      <br />
      <div class="${styles.solid}"></div>
      <p class="${styles.textCenter}">identification document</p>
      <p>series _________ number ____________</p>
      <p>issued __________________________</p>
      <p>________________________________</p>
    </div>

    <p class="${styles.textCenter}">REQUEST</p>
    <p>In accordance with Article 14 of Federal Law No. 152-FZ of July 27, 2006 "On Personal Data" (hereinafter – Federal Law No. 152-FZ), I ask you to provide information regarding the processing of my personal data (personal data of the representee), specifically:</p>

    <ul class="${styles.list}">
      <li>1) confirmation of the fact of processing personal data by Bank 131 LLC;</li>
      <li>2) legal basis and purposes of personal data processing;</li>
      <li>3) purposes and methods of personal data processing used by Bank 131 LLC;</li>
      <li>4) the name and location of Bank 131 LLC, information about persons (with the exception of employees of Bank 131 LLC) who have access to personal data or to whom personal data may be disclosed on the basis of an agreement with Bank 131 LLC or on the basis of federal law;</li>
      <li>5) processed personal data related to the relevant personal data subject, the source of their receipt, unless another procedure for submitting such data is provided for by federal law;</li>
      <li>6) terms of processing of personal data, including the terms of their storage;</li>
      <li>7) the procedure for the exercise by the subject of personal data of the rights provided for by Federal Law No. 152-FZ;</li>
      <li>8) information about completed or intended cross-border data transfers;</li>
      <li>9) the name or surname, first name, patronymic and address of the person who processes personal data on behalf of Bank 131 LLC, if processing has been or will be entrusted to such a person;</li>
      <li>10) other information provided by Federal Law No. 152-FZ or other federal laws.</li>
    </ul>
    <p>In case of absence of such information, I ask you to notify me about it.</p>
    <p>I ask you to send a response to this request in writing to the above address within the time limits prescribed by law.</p>

    <div class="${styles.signature}">
      <div>
        <div class="${styles.solid}"></div>
        <p class="${styles.textCenter}">(signature)</p>
        <p>«___» ______________ 20____</p>
      </div>
      <div class="${styles.signatureFullName}">
        <div class="${styles.solid}"></div>
        <br />
        <p class="${styles.textCenter}">(Full name)</p>
      </div>
    </div>
  </div>
</div>`
};
