import React from 'react';
import PropTypes from 'prop-types';

import './PrivacyPolicyPageContainer.scss';

const parentClassName = 'PrivacyPolicyPageContainer';

export const styles = {
  root: `${parentClassName}_root`,
}

export const PrivacyPolicyPageContainer = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};

PrivacyPolicyPageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
