import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './PrivacyPolicyPageContent.scss';

const parentClassName = 'PrivacyPolicyPageContent';

export const styles = {
  applicationHeader: `${parentClassName}_applicationHeader`,
  contents: `${parentClassName}_contents`,
  documentDisclaimer: `${parentClassName}_documentDisclaimer`,
  documentHeader: `${parentClassName}_documentHeader`,
  documentNameBox: `${parentClassName}_documentNameBox`,
  documentName: `${parentClassName}_documentName`,
  heading: `${parentClassName}_heading`,
  right: `${parentClassName}_right`,
  root: `${parentClassName}_root`,
  list: `${parentClassName}_list`,
  page: `${parentClassName}_page`,
  pageWithoutBottomMargin: `${parentClassName}_pageWithoutBottomMargin`,
  paragraph: `${parentClassName}_paragraph`,
  signature: `${parentClassName}_signature`,
  signatureFullName: `${parentClassName}_signatureFullName`,
  solid: `${parentClassName}_solid`,
  textCenter: `${parentClassName}_textCenter`,
};

export const PrivacyPolicyPageContent = ({ content }) => <div dangerouslySetInnerHTML={{ __html: content }} />

PrivacyPolicyPageContent.propTypes = {
  content: PropTypes.string.isRequired,
};
